import { Component, OnInit } from '@angular/core';
import { StudentService } from '../services/student.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { Game } from '../models/Game';
import { isNullOrUndefined } from 'util';
import { CommonMethods } from '../helper/Method';
import { ModulesEnum } from '../models/ModulesEnum';
import { PermissionEnum } from '../models/PermissionEmun';
@Component({
  selector: 'app-game-add',
  templateUrl: './game-add.component.html',
  styleUrls: ['./game-add.component.css']
})
export class GameAddComponent implements OnInit {
  game = new Game();
  tempgame: any;
  beltList: any;
  GameForm: FormGroup;
  beltversionlist: any;
  isEditGameMod: boolean = false;
  headerTitle = "Add Game";
  submitted = false;
  currentUser: any;
  constructor(private _methods: CommonMethods, private formBuilder: FormBuilder, private studentService: StudentService, private toastr: ToastrService, private _router: Router) {
    const user = atob(sessionStorage.getItem('authData'));
    this.currentUser = JSON.parse(user);
  }

  ngOnInit() {

    this.GameForm = this.formBuilder.group({
      GameName: ['', Validators.required],
      IsActive: ['', Validators.required],
      BeltId: ['', Validators.required],
      BeltVersionId: ['', Validators.required],
      SortOrder: ['', Validators.required]
    });
    let gameId = null;
    if (!isNullOrUndefined(window.localStorage.getItem("GameId"))) {
      gameId = atob(window.localStorage.getItem("GameId"));
    }

    //debugger;
    if (!isNullOrUndefined(gameId)) {
      if (this._methods.CheckUserPermission(ModulesEnum.ManageGameInfo, PermissionEnum.Update)) {
        this.isEditGameMod = true;
        this.headerTitle = "Edit Game";
        this.getGameByGameId(gameId);
      }
      else {
        this.toastr.error("You don't have permission.");
      }
    }
    else {
      if (this._methods.CheckUserPermission(ModulesEnum.ManageGameInfo, PermissionEnum.Create)) {
        this.isEditGameMod = false;
        this.headerTitle = "Add Game";
        this.game = new Game();
      }
      else {
        this.toastr.error("You don't have permission.");
      }
    }
    this.getBeltList();
  }

  get f() { return this.GameForm.controls; }


  CancelAdd() {
    this._router.navigate(['gamelist'])
  }

  //Get Belt List
  getBeltList() {
    this.studentService.GetAllBelt().subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.beltList = res.Data;
      }

    });
  }

  getGameByGameId(gameId) {
    this.studentService.GetGameById(gameId).subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.game = res.Data;
        localStorage.setItem("tempGameName", btoa(this.game.GameName));
        localStorage.setItem("tempBeltId", btoa(this.game.BeltId));
        localStorage.setItem("tempBeltVersionId", btoa(this.game.BeltVersionId));
        if (!isNullOrUndefined(this.game.BeltVersionId)) {
          this.getBeltVersionListByBeltId(this.game.BeltId);
        }
      }

    });
  }

  //get beltversion list
  getBeltVersionListByBeltId(beltid) {
    this.beltversionlist = [];
    this.studentService.GetBeltVersionByBeltId(beltid).subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.beltversionlist = res.Data;

      }

    });
  }

  changeBelt(beltId) {
    if (!isNullOrUndefined(beltId) && beltId != "") {
      this.getBeltVersionListByBeltId(beltId);
    }

  }

  onSubmit() {
    this.submitted = true;
    if (this.GameForm.invalid) {
      return;
    }
    if (this.isEditGameMod && this.game.GameId > 0) {
      if (this._methods.CheckUserPermission(ModulesEnum.ManageGameInfo, PermissionEnum.Update)) {
        this.studentService.CheckGameExists(this.game).subscribe((res: any) => {
          if (res.StatusCode === 200) {
            if (res.Data.GameName != atob(localStorage.getItem("tempGameName")) || res.Data.BeltId != atob(localStorage.getItem("tempBeltId")) || res.Data.BeltVersionId != atob(localStorage.getItem("tempBeltVersionId"))) {
              this.toastr.error("Game already exists.");
              return;
            }
            else {
              this.UpdateGame();
            }
          }
          else {
            this.UpdateGame();
          }
        });
      } else {
        this.toastr.error("You don't have permission.");
      }

    }
    else {
      if (this._methods.CheckUserPermission(ModulesEnum.ManageGameInfo, PermissionEnum.Create)) {
        this.studentService.CheckGameExists(this.game).subscribe((res: any) => {
          if (res.StatusCode === 200) {
            this.toastr.error("Game already exists.");
            return;
          }
          else {
            this.InsertGame();
          }
        });
      } else {
        this.toastr.error("You don't have permission.");
      }
    }
  }

  //update game detail
  UpdateGame() {
    this.game.ModifiedBy = this.currentUser.UserID;
    this.game.IsActive = this.game.IsActive == "true" ? true : false;
    this.studentService.UpdateGame(this.game).subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.toastr.success(res.Data);
        this._router.navigate(['gamelist'])
      }
      else if (res.StatusCode === 409) {
        this.toastr.error(res.Message, res.Data);
      }
      else if (res.StatusCode === 400) {
        this.toastr.error(res.Message, res.Data);
      }
      else {
        this.toastr.error(res.Message, res.Data);
      }
    }, error => {

      this.toastr.error(error, 'Editing Game Failed!');
    });
  }

  //insert game detail
  InsertGame() {
    this.game.CreatedBy = this.currentUser.UserID;

    this.studentService.InsertGame(this.game).subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.toastr.success(res.Data);
        this._router.navigate(['gamelist'])
      }
      else if (res.StatusCode === 409) {
        this.toastr.error(res.Message, res.Data);
      }
      else if (res.StatusCode === 400) {
        this.toastr.error(res.Message, res.Data);
      }
      else {
        this.toastr.error(res.Message, res.Data);
      }
    }, error => {

      this.toastr.error(error, 'Adding Game Failed!');
    });
  }
  //Allow Only digit
  isNumberKey(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }
}
