import { Component, OnInit } from '@angular/core';
import { Student } from '../models/student';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { StudentService } from '../services/student.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DatepickerOptions } from 'ng2-datepicker';
import * as frLocale from 'date-fns/locale/en';
import * as moment from 'moment';
import { isNullOrUndefined } from 'util';
import { CommonMethods } from '../helper/Method';
import { ModulesEnum } from '../models/ModulesEnum';
import { PermissionEnum } from '../models/PermissionEmun';
@Component({
  selector: 'app-addstudent',
  templateUrl: './addstudent.component.html',
  styleUrls: ['./addstudent.component.css']
})
export class AddstudentComponent implements OnInit {
  student = new Student();
  submitted = false;
  StudentForm: FormGroup;
  currentUser;
  studentHeader = "Add Ninja";
  isEditStudentMod = false;
  beltList: any = [];
  gameList: any = [];
  beltversionlist: any = [];
  dateofbirthoption: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2099,
    displayFormat: 'MM-DD-YYYY',
    barTitleFormat: 'MM-DD-YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0,
    locale: frLocale,
    barTitleIfEmpty: 'MM-DD-YYYY',
    placeholder: 'MM-DD-YYYY', // HTML input placeholder attribute (default: '')
    addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
    addStyle: {},
    fieldId: 'searchFromDate',
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };

  lastParentReviewDateoption: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2099,
    displayFormat: 'MM-DD-YYYY',
    barTitleFormat: 'MM-DD-YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0,
    locale: frLocale,
    barTitleIfEmpty: 'MM-DD-YYYY',
    placeholder: 'MM-DD-YYYY', // HTML input placeholder attribute (default: '')
    addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
    addStyle: {},
    fieldId: 'searchFromDate',
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };

  nextParentReviewDateoption: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2099,
    displayFormat: 'MM-DD-YYYY',
    barTitleFormat: 'MM-DD-YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0,
    locale: frLocale,
    barTitleIfEmpty: 'MM-DD-YYYY',
    placeholder: 'MM-DD-YYYY', // HTML input placeholder attribute (default: '')
    addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
    addStyle: {},
    fieldId: 'searchFromDate',
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };

  constructor(private _methods: CommonMethods, private formBuilder: FormBuilder, private studentService: StudentService, private toastr: ToastrService, private _router: Router) {
    const user = atob(sessionStorage.getItem('authData'));
    this.currentUser = JSON.parse(user);

    this.getBeltList();

  }

  ngOnInit() {

    this.StudentForm = this.formBuilder.group({
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      BucksEarned: ['', Validators.required],
      AKAName: [''],
      NinjaBackground: [''],
      LastParentReviewDate: [''],
      NextParentReviewDate: [''],
      BeltId: ['', Validators.required],
      GameId: ['', Validators.required],
      Status: ['', Validators.required],
      DateOfBirth: ['', Validators.required],
      BeltVersionId: ['', Validators.required]
    });
    let studentId = !isNullOrUndefined(window.localStorage.getItem("StudentId")) ? atob(window.localStorage.getItem("StudentId")) : null;
    if (!studentId) {
      this.student.Status = 1;
      this.isEditStudentMod = false;
      //this.getGameList();
      if (!this._methods.CheckUserPermission(ModulesEnum.ManageNinjaInfo, PermissionEnum.Create)) {
        this._methods.logOut();
      }
    }
    else {
      if (!this._methods.CheckUserPermission(ModulesEnum.ManageNinjaInfo, PermissionEnum.Update)) {
        this._methods.logOut();
      }
      this.studentHeader = "Edit Ninja";
      this.isEditStudentMod = true;
      this.getStudentByID(studentId);

    }

  }
  get f() { return this.StudentForm.controls; }

  getStudentByID(studentId) {
    this._methods.showLoader();
    this.studentService.GetStudentById(studentId).subscribe((res: any) => {
      // this.ShowSpinner = false;  
      if (res.StatusCode === 200) {

        this._methods.hideLoader();
        this.student = res.Data;
        this.student.Status = res.Data.Status === true ? 1 : 0;
        this.getGameListByBelt(this.student.BeltId);
        this.getBeltVersionListByBeltId(this.student.BeltId);
        //this.student.NinjaBackground = this.student.NinjaBackground;

        //this._router.navigate(['login']);
      } else if (res.statusCode === 409) {
        this._methods.hideLoader();
        this.toastr.error(res.message, res.Data);
        this._router.navigate(['studentlist']);
      }
      else if (res.StatusCode === 400) {
        this._methods.hideLoader();
        this.toastr.error(res.Message, res.Data);
        this._router.navigate(['studentlist']);
      }
      else {
        this._methods.hideLoader();
        this.toastr.error(res.Message, res.Data);
        this._router.navigate(['studentlist']);
      }
    }, error => {
      this._methods.hideLoader();
      // toaster config --message, title, ToastConfig       
      this.toastr.error('Student not found!');

    });

  }

  getBeltList() {
    this.studentService.GetBeltList().subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.beltList = res.Data;
      }

    });
  }

  getGameList() {
    this.studentService.GetGameList().subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.gameList = res.Data;
      }

    });
  }

  getGameListByBelt(beltId) {
    this.gameList = [];
    this.studentService.GetGameListByBeltId(beltId).subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.gameList = res.Data;
      }

    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.StudentForm.invalid) {
      return;
    }
    this._methods.showLoader();

    this.student.DateOfBirth = (this.student.DateOfBirth != null) ? moment(this.student.DateOfBirth).format('MM-DD-YYYY') : null;
    this.student.LastParentReviewDate = (this.student.LastParentReviewDate != null) ? moment(this.student.LastParentReviewDate).format('MM-DD-YYYY') : null;
    this.student.NextParentReviewDate = (this.student.NextParentReviewDate != null) ? moment(this.student.NextParentReviewDate).format('MM-DD-YYYY') : null;

    if (this.isEditStudentMod && this.student.StudentId > 0) {
      this.student.ModifiedBy = this.currentUser.UserID;
      this.student.Status = this.student.Status == "1" ? true : false;


      if (!isNullOrUndefined(this.student.AKAName)) {
        this.student.AKAName = this.student.AKAName.replace(/,\s*$/, "");
      }
      if (!isNullOrUndefined(this.student.NinjaBackground)) {
        this.student.NinjaBackground = this.student.NinjaBackground.replace(/,\s*$/, "");
      }

      // debugger;

      this.studentService.UpdateStudent(this.student).subscribe((res: any) => {
        if (res.StatusCode === 200) {
          this._methods.hideLoader();
          this.toastr.success(res.Data);
          this._router.navigate(['studentlist'])
        }
        else if (res.StatusCode === 409) {
          this._methods.hideLoader();
          this.toastr.error(res.Message, res.Data);
        }
        else if (res.StatusCode === 400) {
          this._methods.hideLoader();
          this.toastr.error(res.Message, res.Data);
        }
        else {
          this._methods.hideLoader();
          this.toastr.error(res.Message, res.Data);
        }
      }, error => {
        this._methods.hideLoader();
        this.toastr.error(error, 'Updating Ninja Failed!');
      });
    }
    else {
      this.student.CreatedBy = this.currentUser.userID;
      this.student.Status == "1" ? true : false;
      if (!isNullOrUndefined(this.student.AKAName)) {
        this.student.AKAName = this.student.AKAName.replace(/,\s*$/, "");
      }
      if (!isNullOrUndefined(this.student.NinjaBackground)) {
        this.student.NinjaBackground = this.student.NinjaBackground.replace(/,\s*$/, "");
      }
      this.studentService.InsertStudent(this.student).subscribe((res: any) => {
        if (res.StatusCode === 200) {
          this._methods.hideLoader();
          this.toastr.success(res.Data);
          this._router.navigate(['studentlist'])
        }
        else if (res.StatusCode === 409) {
          this._methods.hideLoader();
          this.toastr.error(res.Message, res.Data);
        }
        else if (res.StatusCode === 400) {
          this._methods.hideLoader();
          this.toastr.error(res.Message, res.Data);
        }
        else {
          this._methods.hideLoader();
          this.toastr.error(res.Message, res.Data);
        }
      }, error => {
        this._methods.hideLoader();
        this.toastr.error(error, 'Adding Ninja Failed!');
      });
    }
  }

  beltChangeEvent() {
    if (this.student.BeltId > 0) {
      this.getGameListByBelt(this.student.BeltId);
      this.getBeltVersionListByBeltId(this.student.BeltId);
    }

  }

  cancelSaveStudent() {
    this._router.navigate(['studentlist'])
  }

  //get beltversion list
  getBeltVersionListByBeltId(beltid) {
    this.beltversionlist = [];
    this.studentService.GetBeltVersionByBeltId(beltid).subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.beltversionlist = res.Data;

      }

    });
  }
}
